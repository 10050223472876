
td{
    color: #808992;
    font-weight: 500;  
}
.item {
    font-size: 1em;
    color: #216fed;
}
  .text-content {
    margin-top: 1.125rem;
    margin-bottom: 1.225rem;
    width: 100%;
    text-align: justify;
}
  .comment-review {
      margin-bottom: 0.9375rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .details p{
          margin-bottom: 5px !important;
          margin-top: 5px !important;
  }
  .details span{
    color: #242424 !important;
    font-size: 1rem !important; 
}
.img-fluid-two {
    height: auto;
    min-height: 55px !important;
    max-height: 55px !important;
    max-width: 75px;
    min-width: 75px !important;
}
img.img-fluid-one {
    height: 280px;
    width: 100%;
}
.slide-item-list li a {
padding-right: 0px !important; 
}
.slide-item-list li a img {
    width: 100%;
    cursor: pointer;
}
.tab-slide-content.new-arrival-product .slide-item-list li {
    display: inline-block;
    flex: 0 0 20%;
    width: 20%;
    padding: 0;
    gap:10px;
}

ul.nav.slide-item-list.nav{
    row-gap: 10px;
}


input-group > .form-control:focus, .input-group > .form-select:focus {
    z-index: 1 !important;
}
ul.property-features {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.card.details-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 0px;
  background: transparent !important;
}
.card.details-card .card-header:last-child{
  border-bottom: unset;
}.info{
    position: absolute;
    bottom: 37px;
    right: 16px;
}
.my-profile {
  position: relative;
  margin-top: -10.375rem;
  z-index: 1;
  position: relative;
}
.card.details-card .card-header {
  border-left: 2px solid #216fed;
  border-radius: unset;
  margin: 10px;
  border-bottom: unset;
}

.backdrop {
    background:#000;
    height: 400px;
    width: 100%;
    border-radius: 19px;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.1;

}

.image-gallery img {
    height: 13.5rem;
}

@media only screen and (max-width: 35.9375rem){
  .card.details-card{
    grid-template-columns: 1fr !important;
  }

  .front-view img, .backdrop{
    height:250px !important;
  }
  .avtar.mb-3.text-center {
    width: 100%;
    margin-top: 10px;
}
.image-gallery .owl-prev, .image-gallery .owl-next {
  top: 57% !important;
}
ul.property-features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
} 
}