@import "../../abstracts/variable";

////////////////////
// Horizontal Nav
////////////////////
@media (min-width: 64rem) {
    [data-layout="horizontal"] {
        
        .nav-header {
            width: 21.563rem;
			height: 6.5rem;
			top:0;
            .nav-control {
                display: none;
            }

            .brand-logo {
				padding-left: 2.5rem;
				padding-right: 2.5rem;
            }
        }

        .header {
            width: 100%;
			height: 5.5rem;
            padding-left: 21.563rem;
			padding-top:0;
        }

        .deznav {
            width:100%;
            position: relative;
            height: auto;
            padding-bottom: 0;
            top: 0;
            z-index: 2;
			
            .slimScrollDiv {
                overflow: visible!important;

                .deznav-scroll {
                    overflow: visible!important;
                }
            }
			.deznav-scroll {
				overflow: visible!important;
			}
            .slimScrollBar {
                display: none !important;
            }
			.header-profile{
				margin-right: 0.9375rem;
				margin-bottom:0;
				&:hover{
					&> a.nav-link{
						border-radius:3rem;
					}
				}
				img{
					height:2.8125rem;
					width:2.8125rem;
				}
				&> a.nav-link{
					border-radius:3rem;
					padding: 0.3125rem 0.3125rem
				}
				.header-info{
					display:none;
				}
				display:none;
			}
            .nav-user,
            .nav-label {
                display: none;
            }
			
            .metismenu {
                flex-direction: row;
				padding:0.625rem 1.25rem;
				margin-bottom: 0;
                display: inline-flex;
                flex-wrap: wrap;	
                .collapse.in {
                    display: none;
                }
				ul{
					border-left:0;
					@at-root [data-theme-version="dark"]#{&} {
					   box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
					}
				}
                li {
                    flex-direction: column;
                    position: relative;

                    &:hover {
                        &>ul {
                            display: block;
                        }
                    }
                    
                    &>ul {
                        position: absolute;
                        height: auto !important;
                        top: 100%;
                        width: 100%;
                        min-width: 13.75rem;
                        z-index: 999;
                        left: auto;
                        right: auto;
                        padding: 0.5rem 0;
                        display: none;
                        box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
						margin:0;
						background:$white;
                        border-radius: $radius;
							
                        @at-root [data-theme-version="dark"]#{&} {
                           box-shadow:0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
						   background:$dark-card;
                        }

                        li {
                            padding: 0;
    
                            a {
                                transition: all .4s ease-in-out;
                                padding: 0.5rem 1.25rem 0.5rem 1.25rem;
                                margin-left: -.1rem;
								@at-root [direction="rtl"]#{&} {
									padding: 0.5rem 1.25rem 0.5rem 1.25rem;
									text-align: right;
								}
                                &:hover {
                                    border-radius: .4rem;
									padding-left:1.25rem;
                                }
								&:before{
									content:none;
									left: 1.375rem;
									@at-root [direction="rtl"]#{&} {
										left: auto;
										right: 0.375rem;
									}
								}
                            }
                        }
                        
                        ul {
                            left: 100%;
                            top: 0;
							box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
							@at-root [direction="rtl"]#{&} {
								left: auto;
								right: 100%;
							}
                        }
                    }
                }

                &>li {
                    flex: 0 0 auto;
                    position: relative;
                    &>a i{
						margin-right:0.3125rem;
					}
					@at-root [data-theme-version="dark"]#{&} {
						border-color: rgba(255,255,255,0.07);		
						
						&.mm-active{
							border-color:transparent;
						}
						
						
					}
					&:hover,
					&.mm-active{
						padding:0;			
						& > a{
							background:var(--rgba-primary-1)!important;
							color:var(--primary);
							border-radius: 1rem;
							i{
								color:var(--primary);
								background:transparent;
								box-shadow:none;
							}
						}
					}
                    @at-root [direction="rtl"]#{&} {
                        //&:not(:first-child) {
                        //    border-right: lighten($color: $color-pallate-2, $amount: 10%);
                        //}

                        &:first-child {
                            border-right: 0;
                        }

                        

                        @at-root [data-theme-version="dark"]#{&} {
                            border-color: $d-border;
                        }
                    }
                    &>a {
                        padding: 0.9375rem 2.5rem 0.9375rem 0.9375rem;
						margin: 0.125rem 0.125rem;
                        @at-root [direction="rtl"]#{&} {
							padding: 0.9375rem 0.9375rem 0.9375rem 2.5rem;
						}
						
                        i{
                            padding: 0 0.4375rem 0 0;
							height:auto;
							width:auto;
							line-height:1;
                            @at-root [direction="rtl"]#{&} {
                                padding: 0 0 0 0.4375rem;
                            }
                        }
                        .nav-badge {
                            display: none;
                        }
                        &:after {
                            right: 1.25rem;
                            transform: rotate(-135deg) translateY(-50%);
                        }
                    }
                    &:hover {
						border-color:transparent;
						
                        & > ul {
                            display: flex !important;
                            flex-direction: column;
                            flex-wrap: wrap;
                            height: auto !important;
							box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
							border-radius: $radius;
							
							@at-root [data-theme-version="dark"]#{&} {
								box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(0, 0, 0, 0.1);
								background:$dark-card;
							}
							
                        }
                    }
                    &>ul {
                        &>li {
                            &:hover {
                                ul.collapse {
                                    display: block!important;
                                    position: absolute;
                                    left: auto!important;
                                    right: -100%!important;
                                    top: 0!important;
                                }
                            }
                        }
                    }
                    &:nth-last-child(-n + 5) {
                        &>ul {
                        left: auto;
                        right: 0;
                            &>li {
                                &:hover {
                                    ul.collapse {
                                        right: auto!important;
                                        left: -100%!important;
                                    }
                                }
                            }
							&.left{
								left:0;
								&>li {
									&:hover {
										ul.collapse {
											left: 100%!important;
										}
									}
								}
								@at-root [direction="rtl"]#{&} {
									left:auto;
									right:0;
								}
							}
                        }
                    }
                    &:last-child {
                        &>ul {
                            ul {
                                left: -100%;
                            }
							&.left{
								ul{
									left: 100%;
									@at-root [direction="rtl"]#{&} {
										left:auto;
										right:100%;
									}
								}
							}
							
                        }
                    }
                    @at-root [direction="rtl"]#{&} {

                        &:nth-last-child(-n + 3) {
                            &>ul {
                            left: 0;
                            right: auto;
                                &>li {
                                    &:hover {
                                        ul.collapse {
                                            right: -100% !important;
                                            left: auto !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
			.copyright,
			.plus-box{
				display:none;
			}
        }
		
        .content-body {
            margin-left: 0;
			.container-fluid{
				padding-top:2.5rem;
			}
            .page-titles {
                margin-left: 0!important;
                margin-right: 0!important;
                margin-bottom: 1.875rem;
            }
        }

        .footer {
            margin-left: 0;
            margin: 0 auto;
            padding-left: 0;
        }
    }

    [data-header-position="fixed"][data-layout="horizontal"] {
        .deznav {
            top: 6.5rem;
        }
		.header{
			height: 6.5rem;
		}
		.content-body {
			padding-top: 6.5rem;
		}
    } //ok

    [data-header-position="fixed"][data-sidebar-position="fixed"] {
        .deznav {
            position: fixed;
        }
    } //ok

    [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"] {
        .content-body {
            padding-top: 11.5rem;
        }
    } //ok
	[data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="modern"] {
        .content-body {
            padding-top: 13.125rem;
        }
    } //ok
	
    [data-layout="horizontal"][data-container="boxed"] {

        .footer {
            margin-left: 0;
            max-width: 74.9375rem;
            margin: 0 auto;
        }
    }

    [data-layout="horizontal"][data-container="wide"] {
        .page-titles {
            margin-left: -1.875rem;
            margin-right: -1.875rem;
        }
    }

    [data-layout="horizontal"][data-sidebar-style="modern"] {
		 .deznav {
			.header-profile{
				margin-bottom:0;
				img{
					height: 3.75rem;
					width: 3.75rem;
					margin-bottom:0!important;
				}
				&>a.nav-link{
					border:0.0625rem solid $border-color;
					padding: 0.25rem 0.25rem;
					border-radius:3rem;
				}
			} 
		 }
	}
    [data-layout="horizontal"][data-sidebar-style="compact"] {
        .page-titles {
            margin-top: 0;
        }
        
        .deznav {
			.header-profile{
				margin-bottom:0;
				img{
					height: 3.75rem;
					width: 3.75rem;
					margin-bottom:0!important;
				}
				&>a.nav-link{
					border:0.0625rem solid $border-color;
				}
			} 
            .metismenu {
	
                &>li {
                    &>ul {
                        top: 4.5rem;
                    }
                    &>a {
                        padding: 1.125rem 1.25rem 0.625rem 1.25rem;

                        &::after {
                            display: none;
                        }
						.nav-text{
							margin-top: 0.3125rem;
						}
						&>i{
							width:auto;
							margin:0;
							height:auto;
							line-height:1;
							padding:0;
							background:transparent;
							border-radius: 0;
							margin-bottom: 0;
						}
                    }
                    li {
                        text-align: left;
                    }
                }
            }
        }
    }

    [data-sidebar-style="mini"][data-layout="horizontal"] {
        .nav-header {
            width: 7.75rem;
			padding-left: 2.5rem;
			padding-right: 2.5rem;
			
			    .brand-logo {
				    justify-content: start;
					padding-left: 0;
					padding-right: 0;
				}
        }
    
        .header {
            width: 100%;
            padding-left: 7.75rem;
        }

        .metismenu {
            &>li {
				a{
					width: auto;
				}
                &:hover {
                    a {
                        .nav-text {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"] {
        .content-body {
            padding-top: 13.125rem;
        }
    }

    [data-sidebar-position="fixed"][data-layout="horizontal"] {
        .deznav.fixed {
            position: fixed;
			padding: 0 0.9375rem;
            left: 0;
            top: 0;
			border-radius:0;
			width:100%;
        }
    }

}
