.col-md-12.filter-menu {
    display: grid;
    grid-template-columns: 30% 20% 30% 20%;
    gap: 10px;
    align-items: center;
}

.btn-sm, .btn-group-sm > .btn {
    padding: 10px 20px !important;
}

.custom-dropdown-temp{
    width: 60%;
}

.radio-inline {
    padding-top:10px;
}

.new-arrival-content .table td{
    color: #808992;
    font-weight: 500;
}
.new-arrival-content .item {
    font-size: 1em;
    color: #216fed;
}
.new-arrival-content .img-fluid-temp {
    min-height: 302px;
}
.new-arrival-content .infinite-scroll-component {
    overflow: hidden !important;
  }
.card {
    box-shadow: 1px 1px 22px 0rem rgba(82, 63, 105, 0.05) !important;
    /* margin: 18px !important; */
    border:1px solid #edf2f6 !important;
}
.new-arrival-product .new-arrivals-img-contnent img {
    width: 100%;
    transition: all 0.5s;
}
.img-fluid-temp {
    max-width: 100%;
    height: auto;
    min-height: 205px !important;
    max-height: 205px !important;
}
.new-arrival-product .new-arrivals-img-contnent {
    overflow: hidden;
    border-radius: 8px;
}
.infinite-scroll-component p { 
    margin-bottom: 0px !important; 
}

.infinite-scroll-component {
    overflow: none !important;
}