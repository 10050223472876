.patient-map-area{
	position:relative;
	border-radius: 0.75rem;
    overflow: hidden;
	
	a{
		position:absolute;
		bottom:1.875rem;
		left:1.875rem;
	}
	i{
		background: #3e4954;
		position: absolute;
		top: 1.875rem;
		right: 1.875rem;
		width: 3.5rem;
		height: 3.5rem;
		text-align: center;
		line-height: 3.5rem;
		font-size: 1.5rem;
		color: #fff;
		border-radius: 3.5rem;
	}
	img
	{
		width:100%;
	}
}
.iconbox{
    position: relative;
    padding-left: 4.375rem;
	
	i{
		background:#f9f7fa;
		width:3.5rem;
		height:3.5rem;
		line-height:3.5rem;
		border-radius:3.5rem;
		text-align:center;
		font-size:2rem;
		color:var(--primary);
		position:absolute;
		left:0;
		top:0;
	}
	p{
		margin: 0;
		color: #484848;
		font-size: 1.125rem;
		line-height: 1.3;
		font-weight: 500;
	}
	small{
		margin-bottom: 0.3125rem;
		font-size: 1rem;
		display: block;
	}
}
.widget-timeline-icon{
	padding:3.125rem;
	@include respond('laptop') {
		padding:1.875rem;
	}
	@include respond('phone') {
		overflow:scroll;
		padding:0.9375rem;
	}
	.timeline{
		display:flex;
		@include respond('phone') {
			display:block;
			margin-left: 0.625rem;
		}
	}
	li{
		position: relative;
		border-top: 0.375rem solid var(--primary);
		@include respond('phone') {
			border-top: 0;
			border-left: 0.375rem solid var(--primary);
		}
		a{
			padding: 1.5625rem 3.125rem 0rem 0rem;
			display:block;
			@include respond('laptop') {
				padding: 0.9375rem 1.5625rem 0rem 0rem;
			}
			@include respond('phone') {
				padding: 0rem 0rem 1.875rem 1.875rem;
			}
		}
		
		.icon{
			position: absolute;
			width: 1.25rem;
			height: 1.25rem;
			font-size: 1.5rem;
			color: #fff;
			text-align: center;
			line-height: 3.5rem;
			border-radius: 3.5rem;
			left:-0.125rem;
			top: -0.875rem;
			@include respond('phone') {
				left: -0.75rem;
				top: -0.25rem;
			}
		}
		.timeline-panel{
			
		}
		&:last-child{
			border-color:transparent;
			@include respond('phone') {
				border-left: 0.375rem solid transparent;
			}
			
		}
	}
}
.assigned-doctor{
	position:relative;
	.owl-item img{
		width:5.625rem;
	}
	.owl-next,
	.owl-prev{
		position: absolute;
		width: 3.75rem;
		height: 3.75rem;
		line-height: 3.75rem;
		border-radius: 0.75rem;
		top: 50%;
		background: $white;
		color: var(--primary);
		font-size: 1.125rem;
		margin-top: -1.875rem;
		text-align: center;
		@include transitionMedium;
		cursor:pointer;
		box-shadow:0 0.8125rem 1.5625rem 0 rgba(0,0,0,0.13);
		@include respond('phone') {
			width: 2.8125rem;
			height: 2.8125rem;
			line-height: 2.8125rem;
		}	
		&:hover{
			background: #450b5a;
			color: #fff;
			
		}
	}
	.owl-next{
		right:-2.8125rem;
		@include respond('phone') {
			right:-1.5625rem;
		}	
	}
	.owl-prev{
		left:-2.8125rem;
		@include respond('phone') {
			left:-1.5625rem;
		}	
	}
}




















